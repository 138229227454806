<template>
    <div>
        <!--搜索框-->
        <div style="padding-top: 30px;display: flex;justify-content: flex-start">
            <div>
                用户邮箱：
                <el-input v-model="audit.registerMail" placeholder="请输入用户邮箱"></el-input>
            </div>
            <div style="padding-left: 30px">
                <el-button type="primary" @click="findPage">查询</el-button>
            </div>
        </div>

        <!--内容-->
        <div style="padding-top: 50px" v-loading="loading">
            <el-table
                    :data="list"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="user.registerMail"
                        label="用户邮箱"
                        show-overflow-tooltip
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="topUpNum"
                        label="提现金额"
                        show-overflow-tooltip
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="resultContent"
                        label="钱包链接"
                        show-overflow-tooltip
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="createTime"
                        show-overflow-tooltip
                        label="提现时间"
                        align="center">
                </el-table-column>

                <el-table-column
                        prop="recordStatus"
                        show-overflow-tooltip
                        label="审核状态"
                        align="center">
                    <template slot-scope="scope">
                        <el-tag effect="dark"
                                :type="scope.row.recordStatus==1?'':scope.row.recordStatus==2?'success':'danger'">
                            {{scope.row.recordStatus==1?'已处理':scope.row.recordStatus==2?'待处理':'拒绝'}}
                        </el-tag>
                    </template>
                </el-table-column>

                <el-table-column
                        align="center"
                        fixed="right"
                        label="操作">
                    <template slot-scope="scope">

                        <el-dropdown v-if="scope.row.recordStatus==2" trigger="click">
                            <el-button type="text">审核
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>

                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item><span @click="showPass(scope.row.id)">已处理</span>
                                </el-dropdown-item>
                                <el-dropdown-item><span @click="refuse(scope.row.id)">拒绝</span></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>

                        <el-button v-else type="text" disabled>已处理</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--分页-->
        <div style="padding-top: 50px;text-align: center">
            <el-pagination
                    class="pagiantion"
                    :total="total"
                    @current-change="handleSelectionChange"
                    :current-page="audit.pageIndex"
                    :page-size="audit.pageSize"
                    layout="total, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>

        <el-dialog
                title="充值金额"
                :visible.sync="centerDialogVisible"
                width="25%"
                center>
            <div style="text-align: center">
                充值金额：<el-input oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="moneyNum" clearable placeholder="请输入充值金额"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submit">确 定</el-button>
  </span>
        </el-dialog>

    </div>
</template>

<script>
    export default {
        name: "Withdrawal",
        data() {
            return {
                loading: false,
                total: 0,
                audit: {
                    pageIndex: 1,
                    pageSize: 5,
                    registerMail: null
                },
                list: [],
                centerDialogVisible:false,
                id:null,
                moneyNum:null
            }
        },
        methods: {
            submit(){
                if(!this.moneyNum){
                    this.$message.warning('请输入充值金额')
                    return false;
                }
                this.axios.get('/record/pass?id='+this.id+"&moneyNum="+this.moneyNum).then(resp=>{
                    if (resp.data.code==200){
                        this.centerDialogVisible=false
                        this.findPage()
                        this.$message.success(resp.data.message)
                    }
                })
            },
            showPass(id){
                this.$confirm('确定执行此操作吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.get('/record/deal?id='+id).then(resp=>{
                        if (resp.data.code==200){
                            this.$message.success(resp.data.message)
                            this.findPage()
                        }
                    })
                })
            },
            refuse(id){
                this.$confirm('确定执行此操作吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.axios.get('/record/refuse?id='+id).then(resp=>{
                        if (resp.data.code==200){
                            this.$message.success(resp.data.message)
                            this.findPage()
                        }
                    })
                })
            },
            findPage() {
                this.loading = true
                this.axios.post('/record/findPages', this.audit).then(resp => {
                    if (resp.data.code == 200) {
                        this.list = resp.data.data.data;
                        this.total = resp.data.data.total;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            handleSelectionChange(currentPage) {
                this.audit.pageIndex = currentPage;
                this.findPage();
            },
        },
        created() {
            this.findPage()
        }
    }
</script>

<style scoped>
    .el-input {
        width: 200px;
    }
</style>
